<template>
  <div id="app">
    <div class="top">
      <img src="../../../../static/image/left.png" @click="back" alt="" />
      <div class="order_text">绑定银行卡</div>
    </div>

    <div class="box" v-for="item in anyBankcardlist" :key="item">
      <img class="bg" :src="item.bg" alt="" />
      <div class="bankName">
        <div class="bankName_text">{{ item.bankName }}</div>
        <div class="bankName_sex">{{ item.type }}</div>
      </div>
      <div class="bankName_num">···· ···· ···· {{ item.number }}</div>
    </div>

    <div class="add" @click="add">
      <div>+</div>
      <div class="add_text">添加银行卡</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      anyBankcardlist: '',
    };
  },
  methods: {
    add() {
      this.$router.push('/card');
    },
    ///返回上一页
    back() {
      this.$router.push('/money');
    },
  },

  mounted() {
    let params = {
      token: localStorage.getItem('token'),
    };
    this.$api.anyBankcardlist(params).then((res) => {
      if (res.code == 0) {
        this.anyBankcardlist = res.data;
      }
    });
  },
};
</script>
<style lang="less" scoped>
.box {
  margin-bottom: 20px;
  position: relative;
  .bankName_num {
    color: #f3f4f8;
    font-size: 32px;
    position: absolute;
    bottom: 1px;
    left: 60px;
  }
  .bg {
    width: 336px;
    display: block;
    height: 121px;
    margin: 0 auto;
  }
  .bankName {
    position: absolute;
    top: 0;
    left: 80px;
    top: 10px;
  }
  .bankName_text {
    font-size: 17px;
    color: #ffffff;
  }
  .bankName_sex {
    font-size: 13px;
    color: #8cb3e2;
  }
}

.add {
  width: 336px;
  margin-left: 21px;
  margin-top: 20px;
  color: #cecece;
  font-size: 17px;
  height: 77px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  justify-content: center;
  .add_text {
    margin-left: 15px;
  }
}
#app {
  background-color: #f3f4f8;
  height: 100vh;
}
.top {
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 21px;
  background-color: #ffffff;
  img {
    width: 11px;
    height: 20px;
  }
  .order_text {
    font-size: 18px;
    margin-left: 35%;
  }
}
</style>
